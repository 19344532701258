.ta-table {
    border-spacing: 2em 0em;
}

.ta-table .ta-period {
    color: #aaa;
    vertical-align: top;
}

.ta-table ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}



/* The alert message box */
.alert {
    padding: 20px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* The close button */
.alert .close {
    margin-left: 15px;
    font-weight: bold;
    float: right;
    line-height: 20px;
    font-size: 22px;
    cursor: pointer;
    transition: 0.3s;
}

.alert-warning {
    background-color: rgb(252, 248, 227);
    color: #8a6d3b;
    border: 1px solid rgb(250, 235, 204);
}

.alert-warning .close {
    color: #8a6d3b;
}

.alert-info {
    background-color: rgb(217, 237, 247);
    color: rgb(49, 112, 143);
    border: 1px solid rgb(188, 232, 241)
}

.alert-info .close {
    color: rgb(49, 112, 143);
}

/* When moving the mouse over the close button */
.alert .close:hover {
    color: black;
} 
